import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { useAuthContext } from 'src/auth/hooks';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Label from 'src/components/label';
import DialogContent from '@mui/material/DialogContent';
// components
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import useFetcher, { endpoints } from 'src/utils/axios';
import {
  EngineDataWithLanguagePair,
  TranslateEngine,
  TranslationEngineTestResponse,
} from 'src/types/translateEngine';
import {
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import Switch from '@mui/material/Switch';
import useGlossaryList from 'src/hooks/use-glossary-list';
import { useDashboardContext } from 'src/context/hooks';
import { GlossaryList } from 'src/types/glossary';
import {
  googleTranslateLanguages,
  languages,
  deepLLanguages,
  awsLanguages,
} from 'src/assets/data/languages';
import { useFetchTranslateEngine } from './useFetchTranslateEngine';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  priorityLength: number;
  translationEngine?: TranslateEngine;
  setTranslateEngine: (
    translateEngineUpdater: (prevTranslateEngine: TranslateEngine[]) => TranslateEngine[]
  ) => void;
  setTranslationEngineMappedLanguageData: (
    translateEngineUpdater: (
      prevTranslateEngine: EngineDataWithLanguagePair[]
    ) => EngineDataWithLanguagePair[]
  ) => void;
  glossaryList: GlossaryList[];
};

export const getLanguageBasedOnTranslationEngine = (translationEngineVal: string) => {
  if (
    translationEngineVal === 'Google Translate' ||
    translationEngineVal === 'ST Google Translate Default'
  ) {
    return googleTranslateLanguages;
  }
  if (translationEngineVal === 'DeepL' || translationEngineVal === 'ST DeepL Default') {
    return deepLLanguages;
  }
  if (translationEngineVal === 'AWS' || translationEngineVal === 'ST AWS Default') {
    return awsLanguages;
  }
  return languages;
};

export const getLanguageBasedOnTranslationEngineId = (
  translationEngineId: string,
  translationEngines: TranslateEngine[]
) => {
  const translationEngine = translationEngines.find((engine) => engine._id === translationEngineId);
  if (!translationEngine) return [];

  const translationEngineVal = translationEngine.translationEngine;

  if (
    translationEngineVal === 'Google Translate' ||
    translationEngineVal === 'ST Google Translate Default'
  ) {
    return googleTranslateLanguages;
  }
  if (translationEngineVal === 'DeepL' || translationEngineVal === 'ST DeepL Default') {
    return deepLLanguages;
  }
  if (translationEngineVal === 'AWS' || translationEngineVal === 'ST AWS Default') {
    return awsLanguages;
  }
  return languages;
};

export default function TranslationEngineQuickEdit({
  translationEngine,
  open,
  onClose,
  priorityLength,
  setTranslateEngine,
  setTranslationEngineMappedLanguageData,
  glossaryList,
}: Props) {
  const { updateData } = useFetcher();
  const { currentOrg, setCurrentOrgs } = useDashboardContext();
  const { postData, fetchData } = useFetcher();
  const [languageList, setLanguageList] = useState(languages);
  const [isTestingTranslation, setIsTestingTranslation] = useState(false);
  const [filteredGlossaryList, setFilteredGlossaryList] = useState<GlossaryList[]>([]);
  const [isResponseVisible, setIsResponseVisible] = useState(false);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const { fetchTranslateEngineData, fetchTranslateEngineLanguageMappedData } =
    useFetchTranslateEngine();
  const [termsAndCondition, setTermsAndCondition] = useState(false);
  const [isFetchedTermsAndConditionStatus, setIsFetchedTermsAndConditionStatus] = useState(false);
  const { currentUser } = useAuthContext();

  const [translateEngineForm, setTranslateEngineForm] = useState({
    _id: translationEngine?._id,
    org_id: currentOrg ? currentOrg.org_id : '',
    priority: translationEngine?.priority,
    name: translationEngine?.name,
    translationEngine: translationEngine?.translationEngine,
    batchSize: translationEngine?.batchSize,
    apikey: '',
    createdBy: translationEngine?.createdBy,
    lastModifiedBy: translationEngine?.lastModifiedBy,
    createdDate: translationEngine?.createdDate,
    lastModifiedDate: translationEngine?.lastModifiedDate,
    translationEngineStatus: translationEngine?.translationEngineStatus,
    clientID: translationEngine?.clientID,
    url: translationEngine?.url,
    accessToken: translationEngine?.accessToken,
    selectedlanguageisocodes: translationEngine?.selectedlanguageisocodes,
    isCustomTranslationEngine: translationEngine?.isCustomTranslationEngine,
    customEngineMethod: translationEngine?.customEngineMethod,
    customEngineAuthentication: translationEngine?.customEngineAuthentication,
    username: translationEngine?.username,
    password: '',
    tokenPrefix: translationEngine?.tokenPrefix,
    bearerToken: '',
    authURL: translationEngine?.authURL,
    tokenURL: translationEngine?.tokenURL,
    callbackURL: translationEngine?.callbackURL,
    clientSecret: '',
    scope: translationEngine?.scope,
    desiredStructure: translationEngine?.desiredStructure,
    aws_access_key_id: '',
    aws_secret_access_key: '',
    google_cloud_project_id: translationEngine?.google_cloud_project_id,
    google_cloud_location: translationEngine?.google_cloud_location,
    active: termsAndCondition,
    acceptedBy: currentUser?.uid,
    refresh_token: translationEngine?.refresh_token,
    deepLOrChatGPTApiKeyChanged: false,
    googleClientSecretChanged: false,
    deepLOrChatGPTapiKeyError: '',
    googleTranslationEngineError: '',
    AWSSecretAccessKeyChanged: false,
    AWSSecretAccessKeyError: '',
    AWSAccessIDChanged: false,
    AWSAccessIDError: '',
    customBearerTokenChanged: false,
    customBearerTokenError: '',
    customEnginePassword: false,
    customEnginePasswordError: '',
    glossary: translationEngine?.glossary || null,
  });

  const resetChangeAndErrorStates = () => {
    setTranslateEngineForm((prevState) => ({
      ...prevState,
      apikey: '',
      password: '',
      clientSecret: '',
      bearerToken: '',
      aws_access_key_id: '',
      aws_secret_access_key: '',
      deepLOrChatGPTApiKeyChanged: false,
      googleClientSecretChanged: false,
      deepLOrChatGPTapiKeyError: '',
      googleTranslationEngineError: '',
      AWSSecretAccessKeyChanged: false,
      AWSSecretAccessKeyError: '',
      AWSAccessIDChanged: false,
      AWSAccessIDError: '',
      customBearerTokenChanged: false,
      customBearerTokenError: '',
      customEnginePassword: false,
      customEnginePasswordError: '',
    }));
  };

  const [translationEngineTestResponse, setTranslationEngineTestReponse] =
    useState<TranslationEngineTestResponse>({
      response_code: '400',
      response_body: '',
      request_body: translateEngineForm,
      request_url: translateEngineForm?.url || '',
    });

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    glossary: Yup.object().nullable(),
    apikey: Yup.string(),
  });

  const defaultValues = useMemo(
    () => ({
      name: translationEngine?.name || '',
      glossary: translationEngine?.glossary || null,
      apikey: translationEngine?.apikey || '',
    }),
    [translationEngine]
  );

  const getTermsLink = (translationEngineValue: string) => {
    switch (translationEngineValue) {
      case 'DeepL':
        return 'https://www.deepl.com/terms';
      case 'Google Translate':
        return 'https://cloud.google.com/translate/terms';
      case 'AWS':
        return 'https://aws.amazon.com/service-terms/';
      case 'Chat-GPT':
        return 'https://chatgpt.com';
      case 'Custom-Engine':
      default:
        return '#';
    }
  };

  const clearAuthFields = () => ({
    username: '',
    password: '',
    authURL: '',
    tokenURL: '',
    callbackURL: '',
    clientID: '',
    clientSecret: '',
    google_cloud_location: '',
    google_cloud_project_id: '',
    scope: '',
    bearerToken: '',
    tokenPrefix: '',
  });

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const invalidDeepLApiKey = useMemo(
    () => translateEngineForm.apikey === '.(:;*' || translateEngineForm.apikey === '',
    [translateEngineForm.apikey]
  );

  const invalidGoogleClientSecret = useMemo(
    () => translateEngineForm.clientSecret === '.(:;*' || translateEngineForm.clientSecret === '',
    [translateEngineForm.clientSecret]
  );

  const invalidAWSSecretAccessKey = useMemo(
    () =>
      translateEngineForm.aws_secret_access_key === '.(:;*' ||
      translateEngineForm.aws_secret_access_key === '',
    [translateEngineForm.aws_secret_access_key]
  );

  const invalidAWSAccessKeyID = useMemo(
    () =>
      translateEngineForm.aws_access_key_id === '.(:;*' ||
      translateEngineForm.aws_access_key_id === '',
    [translateEngineForm.aws_access_key_id]
  );

  const invalidCustomBearerToken = useMemo(
    () => translateEngineForm.bearerToken === '.(:;*' || translateEngineForm.bearerToken === '',
    [translateEngineForm.bearerToken]
  );

  const invalidCustomPassword = useMemo(
    () => translateEngineForm.password === '.(:;*' || translateEngineForm.password === '',
    [translateEngineForm.password]
  );

  const {
    reset,
    watch,
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = handleSubmit(async () => {
    let updatedFields = {};
    const authType = translateEngineForm.customEngineAuthentication;

    if (authType === 'Bearer Token') {
      updatedFields = {
        username: '',
        password: '',
        authURL: '',
        tokenURL: '',
        callbackURL: '',
        clientID: '',
        clientSecret: '',
        google_cloud_location: '',
        google_cloud_project_id: '',
        scope: '',
      };
    } else if (authType === 'OAuth 2.0') {
      updatedFields = {
        username: '',
        password: '',
        bearerToken: '',
        tokenPrefix: '',
      };
    } else if (authType === 'Basic Authentication') {
      updatedFields = {
        authURL: '',
        tokenURL: '',
        callbackURL: '',
        clientID: '',
        clientSecret: '',
        google_cloud_location: '',
        google_cloud_project_id: '',
        scope: '',
        bearerToken: '',
        tokenPrefix: '',
      };
    } else if (authType === 'No Auth') {
      updatedFields = clearAuthFields();
    }

    setTranslateEngineForm((prevForm) => ({ ...prevForm, ...updatedFields }));

    updateData(
      [endpoints.translateEngine.put, { params: { id: translationEngine?._id } }],
      translateEngineForm
    )
      .then((response) => {
        if (response) {
          fetchTranslateEngineData()
            .then((responseData) => {
              setTranslateEngine(responseData);
              reset();
              resetChangeAndErrorStates();
              onClose();
            })
            .catch((error) => {
              console.error('Error fetching translation engines:', error);
            });
        }
        fetchTranslateEngineLanguageMappedData()
          .then((responseData) => {
            setTranslationEngineMappedLanguageData(responseData);
          })
          .catch((error) => {
            console.error('Error fetching translation engines:', error);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  });

  const handleChange = (event: any) => {
    const { value } = event.target;

    if (!invalidDeepLApiKey) {
      const languageBasedOnTranslationEngine = getLanguageBasedOnTranslationEngine(
        translateEngineForm.translationEngine || ''
      );

      // Filter and map to get selected ISO codes
      const selectedIsoCodes = languageBasedOnTranslationEngine
        .filter((language) => value.includes(language.label))
        .map((language) => language.isoCode);

      setTranslateEngineForm((prevInputValues) => ({
        ...prevInputValues,
        selectedlanguageisocodes: selectedIsoCodes,
      }));
    } else {
      setTranslateEngineForm((prevState) => ({
        ...prevState,
        apiKeyError: 'Please provide a API key.',
      }));
    }
  };

  const handleTranslateEngineValues = (event: any) => {
    const { name } = event.target;
    let { value } = event.target;

    const languageBasedOnTranslationEngine = getLanguageBasedOnTranslationEngine(
      translateEngineForm.translationEngine || ''
    );

    if (name === 'priority') {
      setTranslateEngineForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      return;
    }

    // Filter and map to get selected ISO codes
    if (name === 'selectedlanguageisocodes') {
      const selectedIsoCodes = languageBasedOnTranslationEngine
        .filter((language) => value.includes(language.label))
        .map((language) => language.isoCode);
      value = selectedIsoCodes;
    }

    if (name === 'glossaryList') {
      const selectedGlossary = glossaryList.find((item) => item.name === value);
      setTranslateEngineForm((prev) => ({
        ...prev,
        glossary: selectedGlossary || null,
      }));
    }

    if (
      translateEngineForm.translationEngine === 'DeepL' ||
      translateEngineForm.translationEngine === 'Chat-GPT'
    ) {
      if (name !== 'apikey') {
        if (!invalidDeepLApiKey) {
          setTranslateEngineForm((prevState) => ({
            ...prevState,
            [name]: typeof value === 'string' ? value.replace('.(:;*', '') : value,
            deepLOrChatGPTApiKeyChanged: true,
            deepLOrChatGPTapiKeyError: '',
          }));
        } else {
          setTranslateEngineForm((prevState) => ({
            ...prevState,
            deepLOrChatGPTapiKeyError: 'Please provide a valid API key.',
          }));
        }
      } else {
        setTranslateEngineForm((prevState) => ({
          ...prevState,
          [name]: name === 'apikey' ? value.replace('.(:;*', '') : value,
          deepLOrChatGPTApiKeyChanged:
            name === 'apikey' ? true : prevState.deepLOrChatGPTApiKeyChanged,
          deepLOrChatGPTapiKeyError: '',
        }));
      }
    }

    if (translateEngineForm.translationEngine === 'Google Translate') {
      if (name !== 'clientSecret') {
        if (!invalidGoogleClientSecret) {
          setTranslateEngineForm((prevState) => ({
            ...prevState,
            [name]: typeof value === 'string' ? value.replace('.(:;*', '') : value,
            googleClientSecretChanged: true,
            googleTranslationEngineError: '',
          }));
        } else {
          setTranslateEngineForm((prevState) => ({
            ...prevState,
            googleTranslationEngineError: 'Please provide a valid Client Secret.',
          }));
        }
      } else {
        setTranslateEngineForm((prevState) => ({
          ...prevState,
          [name]: name === 'clientSecret' ? value.replace('.(:;*', '') : value,
          googleClientSecretChanged:
            name === 'clientSecret' ? true : prevState.googleClientSecretChanged,
          googleTranslationEngineError: '',
        }));
      }
    }

    if (translateEngineForm.translationEngine === 'AWS') {
      if (name === 'aws_access_key_id') {
        setTranslateEngineForm((prevState) => ({
          ...prevState,
          [name]: name === 'aws_access_key_id' ? value.replace('.(:;*', '') : value,
          AWSAccessIDChanged: name === 'aws_access_key_id' ? true : prevState.AWSAccessIDChanged,
          AWSAccessIDError: '',
        }));
      }
      if (name === 'aws_secret_access_key') {
        setTranslateEngineForm((prevState) => ({
          ...prevState,
          [name]: name === 'aws_secret_access_key' ? value.replace('.(:;*', '') : value,
          AWSSecretAccessKeyChanged:
            name === 'aws_secret_access_key' ? true : prevState.AWSSecretAccessKeyChanged,
          AWSSecretAccessKeyError: '',
        }));
      }
      if (name !== 'aws_access_key_id' && name !== 'aws_secret_access_key') {
        if (!invalidAWSAccessKeyID && !invalidAWSSecretAccessKey) {
          setTranslateEngineForm((prevState) => ({
            ...prevState,
            [name]: typeof value === 'string' ? value.replace('.(:;*', '') : value,
            AWSAccessIDChanged: true,
            AWSAccessIDError: '',
            AWSSecretAccessKeyChanged: true,
            AWSSecretAccessKeyError: '',
          }));
        } else {
          setTranslateEngineForm((prevState) => ({
            ...prevState,
            AWSAccessIDError: prevState.AWSAccessIDChanged
              ? ''
              : 'Please provide a AWS Access Key ID.',
            AWSSecretAccessKeyError: prevState.AWSSecretAccessKeyChanged
              ? ''
              : 'Please provide a AWS Secret Access Key.',
          }));
        }
      }
    }

    if (translateEngineForm.translationEngine === 'Custom-Engine') {
      if (name === 'customEngineAuthentication' || name === 'desiredStructure') {
        setTranslateEngineForm((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }

      if (
        translateEngineForm.customEngineAuthentication === 'Bearer Token' &&
        translateEngineForm.desiredStructure !== 'aws'
      ) {
        if (name !== 'bearerToken') {
          if (!invalidCustomBearerToken) {
            setTranslateEngineForm((prevState) => ({
              ...prevState,
              [name]: typeof value === 'string' ? value.replace('.(:;*', '') : value,
              customBearerTokenChanged: true,
              customBearerTokenError: '',
            }));
          } else {
            setTranslateEngineForm((prevState) => ({
              ...prevState,
              customBearerTokenError: 'Please provide a Bearer Token.',
            }));
          }
        } else {
          setTranslateEngineForm((prevState) => ({
            ...prevState,
            [name]: name === 'bearerToken' ? value.replace('.(:;*', '') : value,
            customBearerTokenChanged:
              name === 'bearerToken' ? true : prevState.customBearerTokenChanged,
            customBearerTokenError: '',
          }));
        }
      }

      if (
        (translateEngineForm.customEngineAuthentication === 'Bearer Token' ||
          translateEngineForm.customEngineAuthentication === 'Basic Authentication' ||
          translateEngineForm.customEngineAuthentication === 'No Auth') &&
        translateEngineForm.desiredStructure === 'aws'
      ) {
        if (name === 'aws_access_key_id') {
          setTranslateEngineForm((prevState) => ({
            ...prevState,
            [name]: name === 'aws_access_key_id' ? value.replace('.(:;*', '') : value,
            AWSAccessIDChanged: name === 'aws_access_key_id' ? true : prevState.AWSAccessIDChanged,
            AWSAccessIDError: '',
          }));
        }
        if (name === 'aws_secret_access_key') {
          setTranslateEngineForm((prevState) => ({
            ...prevState,
            [name]: name === 'aws_secret_access_key' ? value.replace('.(:;*', '') : value,
            AWSSecretAccessKeyChanged:
              name === 'aws_secret_access_key' ? true : prevState.AWSSecretAccessKeyChanged,
            AWSSecretAccessKeyError: '',
          }));
        }
        if (name !== 'aws_access_key_id' && name !== 'aws_secret_access_key') {
          if (!invalidAWSAccessKeyID && !invalidAWSSecretAccessKey) {
            setTranslateEngineForm((prevState) => ({
              ...prevState,
              [name]: typeof value === 'string' ? value.replace('.(:;*', '') : value,
              AWSAccessIDChanged: true,
              AWSAccessIDError: '',
              AWSSecretAccessKeyChanged: true,
              AWSSecretAccessKeyError: '',
            }));
          } else {
            setTranslateEngineForm((prevState) => ({
              ...prevState,
              AWSAccessIDError: prevState.AWSAccessIDChanged
                ? ''
                : 'Please provide a AWS Access Key ID.',
              AWSSecretAccessKeyError: prevState.AWSSecretAccessKeyChanged
                ? ''
                : 'Please provide a AWS Secret Access Key.',
            }));
          }
        }
      }

      if (translateEngineForm.customEngineAuthentication === 'OAuth 2.0') {
        if (name === 'aws_access_key_id') {
          setTranslateEngineForm((prevState) => ({
            ...prevState,
            [name]: name === 'aws_access_key_id' ? value.replace('.(:;*', '') : value,
            AWSAccessIDChanged: name === 'aws_access_key_id' ? true : prevState.AWSAccessIDChanged,
            AWSAccessIDError: '',
          }));
        }
        if (name === 'aws_secret_access_key') {
          setTranslateEngineForm((prevState) => ({
            ...prevState,
            [name]: name === 'aws_secret_access_key' ? value.replace('.(:;*', '') : value,
            AWSSecretAccessKeyChanged:
              name === 'aws_secret_access_key' ? true : prevState.AWSSecretAccessKeyChanged,
            AWSSecretAccessKeyError: '',
          }));
        }
        if (name !== 'aws_access_key_id' && name !== 'aws_secret_access_key') {
          if (name !== 'clientSecret') {
            if (!invalidGoogleClientSecret) {
              setTranslateEngineForm((prevState) => ({
                ...prevState,
                [name]: typeof value === 'string' ? value.replace('.(:;*', '') : value,
                googleClientSecretChanged: true,
                googleTranslationEngineError: '',
              }));
            } else {
              setTranslateEngineForm((prevState) => ({
                ...prevState,
                googleTranslationEngineError: 'Please provide a valid Client Secret.',
              }));
            }
          } else {
            setTranslateEngineForm((prevState) => ({
              ...prevState,
              [name]: name === 'clientSecret' ? value.replace('.(:;*', '') : value,
              googleClientSecretChanged:
                name === 'clientSecret' ? true : prevState.googleClientSecretChanged,
              googleTranslationEngineError: '',
            }));
          }
        }
      }

      if (
        translateEngineForm.customEngineAuthentication === 'Basic Authentication' &&
        translateEngineForm.desiredStructure !== 'aws'
      ) {
        if (name !== 'password') {
          if (!invalidCustomPassword) {
            setTranslateEngineForm((prevState) => ({
              ...prevState,
              [name]: typeof value === 'string' ? value.replace('.(:;*', '') : value,
              customEnginePassword: true,
            }));
          } else {
            setTranslateEngineForm((prevState) => ({
              ...prevState,
              customEnginePasswordError: 'Please provide a Password.',
            }));
          }
        } else {
          setTranslateEngineForm((prevState) => ({
            ...prevState,
            [name]: name === 'password' ? value.replace('.(:;*', '') : value,
            customEnginePassword: name === 'password' ? true : prevState.customEnginePassword,
            customEnginePasswordError: '',
          }));
        }
      }

      if (
        translateEngineForm.customEngineAuthentication === 'No Auth' &&
        translateEngineForm.desiredStructure !== 'aws'
      ) {
        setTranslateEngineForm((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }
  };

  const handleCheckboxChange = () => {
    setTermsAndCondition(!termsAndCondition);
    setTranslateEngineForm((prevInputValues) => ({
      ...prevInputValues,
      active: !termsAndCondition,
    }));
  };

  const testTranslation = async () => {
    setIsTestingTranslation(true);

    try {
      if (
        translateEngineForm.isCustomTranslationEngine &&
        (translateEngineForm.translationEngine === 'Google Translate' ||
          translateEngineForm.customEngineAuthentication === 'OAuth 2.0') &&
        !translateEngineForm.refresh_token
      ) {
        const authResponse = await postData(endpoints.authenticate, translateEngineForm);

        if (!authResponse?.success) {
          throw new Error(
            authResponse?.error ||
              'Authentication failed: Authorization code not received or invalid response.'
          );
        }

        setTranslateEngineForm((prevState) => ({
          ...prevState,
          accessToken: authResponse.auth_response,
        }));
      }

      const translationPayload = {
        data: { ...translateEngineForm },
        org_id: currentOrg?.org_id || '',
      };

      const translationResponse = await postData(
        endpoints.translateEngine.testTranslation,
        translationPayload
      );

      if (translationResponse?.status === 'success') {
        setTranslationEngineTestReponse({
          response_code: translationResponse.status,
          request_body: translateEngineForm,
          response_body: translationResponse,
          request_url: translationResponse.request_url || translateEngineForm.url,
        });
      } else {
        setTranslationEngineTestReponse({
          response_code: translationResponse.status,
          request_body: translateEngineForm,
          response_body: String(translationResponse?.message),
          request_url: translationResponse.request_url || translateEngineForm.url,
        });
      }

      setIsResponseVisible(true);
    } catch (error) {
      setTranslationEngineTestReponse({
        response_code: '400',
        request_body: translateEngineForm,
        response_body: String(error),
        request_url: translateEngineForm.url,
      });

      setIsResponseVisible(true);
    } finally {
      setIsTestingTranslation(false);
    }
  };

  const authenticate = handleSubmit(async () => {
    postData(endpoints.authenticate, translateEngineForm)
      .then((response) => {
        setTranslateEngineForm({ ...translateEngineForm, accessToken: response.auth_response });
      })
      .catch((error) => {
        console.error('Authentication failed:', error);
      });
  });

  const filteredForm = Object.fromEntries(
    Object.entries(translationEngineTestResponse.request_body).filter(
      ([key, value]) => value !== ''
    )
  );

  useEffect(() => {
    const filteredGlossaryList_ = glossaryList?.filter(
      (item) =>
        item.customDeeplTranslationEngine === translationEngine?._id ||
        item.customGoogleTranslationEngine === translationEngine?._id
    );
    setFilteredGlossaryList(filteredGlossaryList_);
  }, [translationEngine, glossaryList]);

  useEffect(() => {
    const languageBasedOnTranslationEngine = getLanguageBasedOnTranslationEngine(
      translateEngineForm.translationEngine || ''
    );

    const selectedLanguageLabel = languageBasedOnTranslationEngine
      .filter((language) =>
        translateEngineForm?.selectedlanguageisocodes?.includes(language.isoCode)
      )
      .map((language) => language.label);

    setSelectedLanguages(selectedLanguageLabel);
    setLanguageList(languageBasedOnTranslationEngine);
  }, [translateEngineForm.translationEngine, translateEngineForm?.selectedlanguageisocodes]);

  useEffect(() => {
    setTranslateEngineForm((prevForm) => ({
      ...prevForm,
      refresh_token: translationEngine?.refresh_token || '',
      priority: translationEngine?.priority,
    }));
  }, [translationEngine]);

  const handleStatusUpdate = async () => {
    try {
      const newStatus = !translateEngineForm.translationEngineStatus;

      setTranslateEngineForm((prev) => ({
        ...prev,
        translationEngineStatus: newStatus,
      }));

      await updateData(
        [endpoints.translateEngine.put, { params: { id: translationEngine?._id } }],
        {
          ...translateEngineForm,
          translationEngineStatus: newStatus,
        }
      );
      fetchTranslateEngineData()
        .then((responseData) => {
          setTranslateEngine(responseData);
          onClose();
        })
        .catch((error) => {
          console.error('Error fetching translation engines:', error);
        });
    } catch (error) {
      setTranslateEngineForm((prev) => ({
        ...prev,
        translationEngineStatus: !translateEngineForm.translationEngineStatus,
      }));

      console.error('Error updating status:', error);
    }
  };

  useEffect(() => {
    if (open && translationEngine?._id) {
      setTranslateEngineForm((prev) => ({
        ...prev,
        _id: translationEngine._id,
        org_id: translationEngine.org_id,
        priority: translationEngine.priority,
        name: translationEngine.name,
        translationEngine: translationEngine.translationEngine,
        batchSize: translationEngine.batchSize,
        apikey: translationEngine.apikey || '',
        createdBy: translationEngine.createdBy,
        lastModifiedBy: translationEngine.lastModifiedBy,
        createdDate: translationEngine.createdDate,
        lastModifiedDate: translationEngine.lastModifiedDate,
        translationEngineStatus: translationEngine.translationEngineStatus,
        clientID: translationEngine.clientID || '',
        url: translationEngine.url,
        accessToken: translationEngine.accessToken,
        selectedlanguageisocodes: translationEngine.selectedlanguageisocodes,
        isCustomTranslationEngine: translationEngine.isCustomTranslationEngine,
        customEngineMethod: translationEngine.customEngineMethod,
        customEngineAuthentication: translationEngine.customEngineAuthentication,
        username: translationEngine.username,
        password: translationEngine.password || '',
        tokenPrefix: translationEngine.tokenPrefix,
        bearerToken: translationEngine.bearerToken || '',
        authURL: translationEngine.authURL,
        tokenURL: translationEngine.tokenURL,
        callbackURL: translationEngine.callbackURL,
        clientSecret: translationEngine.clientSecret || '',
        scope: translationEngine.scope,
        desiredStructure: translationEngine.desiredStructure,
        aws_access_key_id: translationEngine.aws_access_key_id || '',
        aws_secret_access_key: translationEngine.aws_secret_access_key || '',
        google_cloud_project_id: translationEngine.google_cloud_project_id,
        google_cloud_location: translationEngine.google_cloud_location,
        refresh_token: translationEngine.refresh_token,
        glossary: translationEngine.glossary || null,
        deepLOrChatGPTApiKeyChanged: false,
        googleClientSecretChanged: false,
        deepLOrChatGPTapiKeyError: '',
        googleTranslationEngineError: '',
        AWSSecretAccessKeyChanged: false,
        AWSSecretAccessKeyError: '',
        AWSAccessIDChanged: false,
        AWSAccessIDError: '',
        customBearerTokenChanged: false,
        customBearerTokenError: '',
        customEnginePassword: false,
        customEnginePasswordError: '',
      }));
    }
  }, [open, translationEngine]);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods}>
        <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
          <DialogTitle>Translation Engine Update</DialogTitle>
        </Box>

        <DialogContent>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
            sx={{ p: 2 }}
          >
            <RHFTextField
              name="name"
              label="Name"
              value={translateEngineForm.name}
              onChange={handleTranslateEngineValues}
              disabled={!translateEngineForm.isCustomTranslationEngine}
            />
            {translateEngineForm.translationEngine === 'Custom-Engine' ? (
              <>
                <RHFTextField
                  name="url"
                  label="URL"
                  value={translateEngineForm.url}
                  onChange={handleTranslateEngineValues}
                  disabled={!translateEngineForm.isCustomTranslationEngine}
                />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Method</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Method"
                    name="customEngineMethod"
                    value={translateEngineForm.customEngineMethod}
                    onChange={handleTranslateEngineValues}
                    disabled={!translateEngineForm.isCustomTranslationEngine}
                  >
                    <MenuItem value="GET">GET</MenuItem>
                    <MenuItem value="POST">POST</MenuItem>
                    <MenuItem value="PUT">PUT</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Authentication Protocol
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Authentication Protocol"
                    name="customEngineAuthentication"
                    value={translateEngineForm.customEngineAuthentication}
                    onChange={handleTranslateEngineValues}
                  >
                    <MenuItem value="Bearer Token">Bearer Token</MenuItem>
                    <MenuItem value="OAuth 2.0">OAuth 2.0</MenuItem>
                    <MenuItem value="Basic Authentication">Basic Authentication</MenuItem>
                    <MenuItem value="No Auth">No Auth</MenuItem>
                  </Select>
                </FormControl>
                {translateEngineForm.customEngineAuthentication !== 'OAuth 2.0' && (
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Desired Structure</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      label="Desired Structure"
                      name="desiredStructure"
                      onChange={handleTranslateEngineValues}
                      value={translateEngineForm.desiredStructure}
                      disabled={!translateEngineForm.isCustomTranslationEngine}
                    >
                      <MenuItem value="aws">AWS</MenuItem>
                      <MenuItem value="google-translate">Google Translate</MenuItem>
                      <MenuItem value="deepl">DeepL</MenuItem>
                      <MenuItem value="chat-gpt">Chat-GPT</MenuItem>
                    </Select>
                  </FormControl>
                )}
                {translateEngineForm.desiredStructure === 'aws' &&
                  translateEngineForm.customEngineAuthentication !== 'OAuth 2.0' && (
                    <>
                      <RHFTextField
                        name="aws_access_key_id"
                        label="AWS Access Key ID"
                        value={
                          translateEngineForm.AWSAccessIDChanged
                            ? translateEngineForm.aws_access_key_id
                            : '.(:;*'
                        }
                        onChange={handleTranslateEngineValues}
                        disabled={!translateEngineForm.isCustomTranslationEngine}
                        fullWidth
                        type="password"
                        error={Boolean(translateEngineForm.AWSAccessIDError)}
                        helperText={translateEngineForm.AWSAccessIDError || ''}
                      />
                      <RHFTextField
                        name="aws_secret_access_key"
                        label="AWS Secret Access Key"
                        value={
                          translateEngineForm.AWSSecretAccessKeyChanged
                            ? translateEngineForm.aws_secret_access_key
                            : '.(:;*'
                        }
                        onChange={handleTranslateEngineValues}
                        disabled={!translateEngineForm.isCustomTranslationEngine}
                        fullWidth
                        type="password"
                        error={Boolean(translateEngineForm.AWSSecretAccessKeyError)}
                        helperText={translateEngineForm.AWSSecretAccessKeyError || ''}
                      />
                    </>
                  )}
                {translateEngineForm.customEngineAuthentication === 'Basic Authentication' &&
                  translateEngineForm.desiredStructure !== 'aws' && (
                    <>
                      <RHFTextField
                        name="username"
                        label="Username"
                        onChange={handleTranslateEngineValues}
                        value={translateEngineForm.username}
                        disabled={!translateEngineForm.isCustomTranslationEngine}
                      />
                      <RHFTextField
                        name="password"
                        label="Password"
                        onChange={handleTranslateEngineValues}
                        value={
                          translateEngineForm.customEnginePassword
                            ? translateEngineForm.password
                            : '.(:;*'
                        }
                        disabled={!translateEngineForm.isCustomTranslationEngine}
                        fullWidth
                        type="password"
                        error={Boolean(translateEngineForm.customEnginePasswordError)}
                        helperText={translateEngineForm.customEnginePasswordError || ''}
                      />
                    </>
                  )}
                {translateEngineForm.customEngineAuthentication === 'Bearer Token' &&
                  translateEngineForm.desiredStructure !== 'aws' && (
                    <>
                      <RHFTextField
                        name="tokenPrefix"
                        label="Token Prefix"
                        placeholder="Bearer"
                        onChange={handleTranslateEngineValues}
                        value={translateEngineForm.tokenPrefix}
                        disabled={!translateEngineForm.isCustomTranslationEngine}
                      />
                      <RHFTextField
                        name="bearerToken"
                        label="Bearer Token"
                        value={
                          translateEngineForm.customBearerTokenChanged
                            ? translateEngineForm.bearerToken
                            : '.(:;*'
                        }
                        onChange={handleTranslateEngineValues}
                        fullWidth
                        type="password"
                        error={Boolean(translateEngineForm.customBearerTokenError)}
                        helperText={translateEngineForm.customBearerTokenError || ''}
                        disabled={!translateEngineForm.isCustomTranslationEngine}
                      />
                    </>
                  )}
              </>
            ) : (
              translateEngineForm?.isCustomTranslationEngine &&
              translateEngineForm.translationEngine !== 'AWS' &&
              translateEngineForm.translationEngine !== 'Google Translate' && (
                <RHFTextField
                  name="apikey"
                  label="API Key"
                  value={
                    translateEngineForm.deepLOrChatGPTApiKeyChanged
                      ? translateEngineForm.apikey
                      : '.(:;*'
                  }
                  onChange={handleTranslateEngineValues}
                  disabled={!translateEngineForm.isCustomTranslationEngine}
                  fullWidth
                  type="password"
                  error={Boolean(translateEngineForm.deepLOrChatGPTapiKeyError)}
                  helperText={translateEngineForm.deepLOrChatGPTapiKeyError || ''}
                />
              )
            )}
            {translateEngineForm?.isCustomTranslationEngine &&
              (translateEngineForm.translationEngine === 'Google Translate' ||
                translateEngineForm.customEngineAuthentication === 'OAuth 2.0') && (
                <>
                  <RHFTextField
                    name="authURL"
                    label="Auth URL"
                    onChange={handleTranslateEngineValues}
                    value={translateEngineForm.authURL}
                    disabled={!translateEngineForm.isCustomTranslationEngine}
                  />
                  <RHFTextField
                    name="tokenURL"
                    label="Token URL"
                    onChange={handleTranslateEngineValues}
                    value={translateEngineForm.tokenURL}
                    disabled={!translateEngineForm.isCustomTranslationEngine}
                  />
                  <RHFTextField
                    name="callbackURL"
                    label="Callback URL"
                    onChange={handleTranslateEngineValues}
                    value={translateEngineForm.callbackURL}
                    disabled={!translateEngineForm.isCustomTranslationEngine}
                  />
                  <RHFTextField
                    name="clientID"
                    label="Client ID"
                    onChange={handleTranslateEngineValues}
                    value={translateEngineForm.clientID}
                    disabled={!translateEngineForm.isCustomTranslationEngine}
                  />

                  <RHFTextField
                    name="clientSecret"
                    label="Client Secret"
                    onChange={handleTranslateEngineValues}
                    value={
                      translateEngineForm.googleClientSecretChanged
                        ? translateEngineForm.clientSecret
                        : '.(:;*'
                    }
                    disabled={!translateEngineForm.isCustomTranslationEngine}
                    type="password"
                    error={Boolean(translateEngineForm.googleTranslationEngineError)}
                    helperText={translateEngineForm.googleTranslationEngineError || ''}
                  />
                  <RHFTextField
                    name="scope"
                    label="Scope"
                    onChange={handleTranslateEngineValues}
                    value={translateEngineForm.scope}
                    disabled={!translateEngineForm.isCustomTranslationEngine}
                  />
                  <RHFTextField
                    name="google_cloud_project_id"
                    label="Google Cloud Project ID"
                    onChange={handleTranslateEngineValues}
                    value={translateEngineForm.google_cloud_project_id}
                    disabled={!translateEngineForm.isCustomTranslationEngine}
                  />
                  <RHFTextField
                    name="google_cloud_location"
                    label="Google Cloud Location"
                    onChange={handleTranslateEngineValues}
                    value={translateEngineForm.google_cloud_location}
                    disabled={!translateEngineForm.isCustomTranslationEngine}
                  />
                </>
              )}
            {translateEngineForm?.isCustomTranslationEngine &&
              translateEngineForm.translationEngine === 'AWS' && (
                <>
                  <RHFTextField
                    name="aws_access_key_id"
                    label="AWS Access Key ID"
                    value={
                      translateEngineForm.AWSAccessIDChanged
                        ? translateEngineForm.aws_access_key_id
                        : '.(:;*'
                    }
                    onChange={handleTranslateEngineValues}
                    disabled={!translateEngineForm.isCustomTranslationEngine}
                    fullWidth
                    type="password"
                    error={Boolean(translateEngineForm.AWSAccessIDError)}
                    helperText={translateEngineForm.AWSAccessIDError || ''}
                  />
                  <RHFTextField
                    name="aws_secret_access_key"
                    label="AWS Secret Access Key"
                    value={
                      translateEngineForm.AWSSecretAccessKeyChanged
                        ? translateEngineForm.aws_secret_access_key
                        : '.(:;*'
                    }
                    onChange={handleTranslateEngineValues}
                    disabled={!translateEngineForm.isCustomTranslationEngine}
                    fullWidth
                    type="password"
                    error={Boolean(translateEngineForm.AWSSecretAccessKeyError)}
                    helperText={translateEngineForm.AWSSecretAccessKeyError || ''}
                  />
                </>
              )}
            <RHFTextField
              name="translationEngine"
              label="Translation Engine"
              value={translateEngineForm.translationEngine}
              disabled={!translateEngineForm.isCustomTranslationEngine}
            />
            <Select
              labelId="demo-simple-select-label"
              multiple
              name="selectedlanguageisocodes"
              value={selectedLanguages}
              onChange={handleTranslateEngineValues}
              renderValue={(selected) => selected.join(', ')}
              disabled={!translateEngineForm.isCustomTranslationEngine}
              sx={{ width: '300px' }}
            >
              {languageList.map((language) => (
                <MenuItem key={language.isoCode} value={language.label}>
                  {language.label} ({language.isoCode})
                </MenuItem>
              ))}
            </Select>
            {translateEngineForm.translationEngine !== 'Custom-Engine' &&
              translateEngineForm?.isCustomTranslationEngine && (
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Glossary List</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Glossary List"
                    name="glossaryList"
                    value={translateEngineForm.glossary?.name || ''}
                    onChange={handleTranslateEngineValues}
                    disabled={!translateEngineForm.isCustomTranslationEngine}
                  >
                    {glossaryList?.map((glossaryListItem, id) => (
                      <MenuItem key={id} value={glossaryListItem.name}>
                        {glossaryListItem.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            <RHFTextField
              name="batchSize"
              label="Batch Size"
              value={translateEngineForm.batchSize}
              onChange={handleTranslateEngineValues}
              disabled={!translateEngineForm.isCustomTranslationEngine}
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select Priority</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label="Priority"
                name="priority"
                value={parseInt(translateEngineForm?.priority || '0', 10)}
                onChange={handleTranslateEngineValues}
              >
                {Array.from({ length: priorityLength }, (_, index) => (
                  <MenuItem key={index + 1} value={index + 1}>
                    {index + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        {!translateEngineForm.isCustomTranslationEngine && (
          <DialogContent>
            <Typography variant="body1" gutterBottom sx={{ fontSize: '0.8rem' }}>
              By activating this Service, you agree to the Provider&#39;s{' '}
              <a
                href={getTermsLink(translateEngineForm?.translationEngine ?? '')}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#1976d2', textDecoration: 'underline' }}
              >
                Terms of Service
              </a>{' '}
            </Typography>
          </DialogContent>
        )}
        <DialogActions>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', gap: 1.5 }}>
            <LoadingButton
              type="button"
              variant="contained"
              loading={isTestingTranslation}
              onClick={testTranslation}
            >
              Test Translation
            </LoadingButton>

            {isResponseVisible && (
              <LoadingButton
                type="button"
                variant="contained"
                onClick={() => {
                  setTranslationEngineTestReponse({
                    response_code: '',
                    response_body: '',
                    request_body: '',
                    request_url: '',
                  });
                }}
              >
                Clear Response
              </LoadingButton>
            )}
            <LoadingButton
              type="button"
              variant="contained"
              onClick={handleStatusUpdate}
              sx={{ mr: 1.5 }}
            >
              {translateEngineForm.translationEngineStatus ? 'Deactivate' : 'Activate'}
            </LoadingButton>
          </Box>
          <Box>
            <Button variant="outlined" onClick={onClose} sx={{ marginRight: 1.5 }}>
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              onClick={onSubmit}
              loading={isSubmitting}
              sx={{ marginRight: 1.5 }}
            >
              Update
            </LoadingButton>
          </Box>
        </DialogActions>
        {isResponseVisible && (
          <DialogContent>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
              sx={{ p: 2 }}
            >
              <Grid md={4}>
                <Typography variant="h6" sx={{ mb: 0.5 }}>
                  Response
                </Typography>
              </Grid>
            </Box>
            <Grid xs={12} md={8}>
              <Card>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <RHFTextField
                    name="responseCode"
                    label="Response Code"
                    InputProps={
                      isResponseVisible
                        ? {
                            startAdornment: (
                              <InputAdornment position="start">
                                <Label
                                  variant="soft"
                                  color={
                                    translationEngineTestResponse?.response_code === 'success'
                                      ? 'success'
                                      : 'error'
                                  }
                                >
                                  {translationEngineTestResponse?.response_code}
                                </Label>
                              </InputAdornment>
                            ),
                            readOnly: true,
                          }
                        : {
                            readOnly: true,
                          }
                    }
                  />

                  <RHFTextField
                    name="requestURL"
                    label="Request URL"
                    value={translationEngineTestResponse.request_url}
                  />
                  <RHFTextField
                    name="requestBody"
                    label="Request Body"
                    multiline
                    rows={6}
                    value={JSON.stringify(filteredForm, null, 2)}
                  />
                  <RHFTextField
                    name="responseBody"
                    label="Response Body"
                    multiline
                    rows={6}
                    value={
                      typeof translationEngineTestResponse.response_body === 'object'
                        ? JSON.stringify(translationEngineTestResponse.response_body)
                        : translationEngineTestResponse.response_body
                    }
                  />
                </Stack>
              </Card>
            </Grid>
          </DialogContent>
        )}
      </FormProvider>
    </Dialog>
  );
}
