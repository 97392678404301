// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import { enqueueSnackbar } from 'src/components/snackbar';

// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { usePopover } from 'src/components/custom-popover';
import { useEffect, useState } from 'react';
import { GlossaryList } from 'src/types/glossary';
import {
  EngineDataWithLanguagePair,
  TranslateEngine,
  TranslateEngineItem,
} from 'src/types/translateEngine';
import { IUser } from 'src/types/user';
import { useTheme } from '@mui/material/styles';
import { Select, Tooltip } from '@mui/material';
import useFetcher, { endpoints } from 'src/utils/axios';
import { useAuthContext } from 'src/auth/hooks';
import { useDashboardContext } from 'src/context/hooks';
import { fDateTime } from '../../utils/format-time';
import TranslateEngineDeleteForm from './delete-translation-engine';
import TranslationEngineQuickEdit from './translation-engine-quick-edit';
import { useFetchTranslateEngine } from './useFetchTranslateEngine';
// ----------------------------------------------------------------------

type Props = {
  users: IUser[];
  row: TranslateEngine;
  selected: boolean;
  onViewRow: VoidFunction;
  glossaryList: GlossaryList[];
  priorityLength: number;
  onSelectRow: VoidFunction;
  onDeleteRow: (id: string) => void;
  setTranslateEngine: (
    translateEngineUpdater: (prevTranslateEngine: TranslateEngine[]) => TranslateEngine[]
  ) => void;
  setTranslationEngineMappedLanguageData: (
    translateEngineUpdater: (
      prevTranslateEngine: EngineDataWithLanguagePair[]
    ) => EngineDataWithLanguagePair[]
  ) => void;
};

export default function TableRowMetadata({
  users,
  row,
  selected,
  onViewRow,
  priorityLength,
  glossaryList,
  onSelectRow,
  onDeleteRow,
  setTranslateEngine,
  setTranslationEngineMappedLanguageData,
}: Props) {
  const { currentOrg } = useDashboardContext();
  const { updateData, fetchData, postData } = useFetcher();
  const { currentUser } = useAuthContext();
  const [allGlossaryList, setAllGlossaryList] = useState<GlossaryList[]>([]);
  const quickDelete = useBoolean();
  const quickEdit = useBoolean();
  const theme = useTheme();

  const [translateEngineForm, setTranslateEngineForm] = useState({
    org_id: currentOrg ? currentOrg.org_id : '',
    name: row.name,
    translationEngine: row.translationEngine,
    glossary: row.glossary,
    apikey: row.apikey,
    createdBy: row.createdBy,
    lastModifiedBy: row.lastModifiedBy,
    createdDate: row.createdDate,
    lastModifiedDate: row.lastModifiedDate,
    clientID: row.clientID,
    url: row.url,
    accessToken: row.accessToken,
    isCustomTranslationEngine: row.isCustomTranslationEngine,
    translationEngineStatus: row.translationEngineStatus,
  });

  const popover = usePopover();
  const handleDelete = () => {
    onDeleteRow(row._id);
  };

  useEffect(() => {
    setTranslateEngineForm((prevState) => ({
      ...prevState,
      name: row.name,
      translationEngine: row.translationEngine,
      apikey: row.apikey,
      glossary: row.glossary,
      createdBy: row.createdBy,
      lastModifiedBy: row.lastModifiedBy,
      isCustomTranslationEngine: row.isCustomTranslationEngine,
      translationEngineStatus: row.translationEngineStatus,
    }));
  }, [row]);

  const buildRequestBody = (
    extraFields: Partial<TranslateEngineItem> = {}
  ): TranslateEngineItem => ({
    _id: row?._id,
    org_id: currentOrg?.org_id || '',
    createdBy: currentUser?.uid || '',
    authURL: row.authURL,
    glossary: row.glossary,
    name: row.name,
    tokenURL: row.tokenURL,
    callbackURL: row.callbackURL,
    clientID: row.clientID,
    clientSecret: row.clientSecret,
    scope: row.scope,
    translationEngine: row.translationEngine,
    lastModifiedBy: row.lastModifiedBy,
    isCustomTranslationEngine: row.isCustomTranslationEngine,
    selectedlanguageisocodes: row.selectedlanguageisocodes,
    desiredStructure: row.desiredStructure,
    ...extraFields,
  });

  const authenticate = () => {
    if (currentOrg && currentUser) {
      const requestBody = buildRequestBody();

      postData(endpoints.authenticate, requestBody)
        .then((response) => {
          const updatedRequestBody = buildRequestBody({ accessToken: response.auth_response });
          updateData(
            [endpoints.translateEngine.put, { params: { id: row._id } }],
            updatedRequestBody
          )
            .then((response_) => {
              if (response_ && response_[0]) {
                setTranslateEngineForm((prevInputValues) => ({
                  ...prevInputValues,
                  lastModifiedDate: response_[0].lastModifiedDate,
                }));
              }
              enqueueSnackbar('Updated successfully');
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((error) => {
          console.error('Authentication failed:', error);
        });
    }
  };

  const getButtonColor = () => {
    if (row.isCustomTranslationEngine) return 'error';
    return popover.open ? 'inherit' : 'default';
  };

  const renderPrimary = (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox
          checked={selected}
          onClick={onSelectRow}
          disabled={!row.isCustomTranslationEngine}
        />
      </TableCell>
      <TableCell>
        <Box>{translateEngineForm.name}</Box>
      </TableCell>
      <TableCell>
        <Box>{translateEngineForm.glossary?.name}</Box>
      </TableCell>
      <TableCell>
        <Box>{translateEngineForm.translationEngine}</Box>
      </TableCell>
      <TableCell>
        <Box>{translateEngineForm.createdBy}</Box>
      </TableCell>
      <TableCell>
        <Box>{translateEngineForm.lastModifiedBy}</Box>
      </TableCell>
      <TableCell>
        <Box>{fDateTime(translateEngineForm.createdDate)}</Box>
      </TableCell>
      <TableCell>
        <Box>{fDateTime(translateEngineForm.lastModifiedDate)}</Box>
      </TableCell>
      <TableCell>
        <TableCell>
          <Label
            variant="soft"
            color={
              (translateEngineForm.translationEngineStatus === true && 'success') ||
              (translateEngineForm.translationEngineStatus === false && 'error') ||
              'default'
            }
          >
            {translateEngineForm.translationEngineStatus === true ? 'Active' : 'Inactive'}
          </Label>
        </TableCell>
      </TableCell>
      <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
        <IconButton color={quickEdit.value ? 'inherit' : 'default'} onClick={quickEdit.onTrue}>
          <Iconify icon="solar:pen-bold" />
        </IconButton>
        <IconButton
          color={getButtonColor()}
          onClick={quickDelete.onTrue}
          disabled={!row.isCustomTranslationEngine}
        >
          <Iconify
            icon="solar:trash-bin-trash-bold"
            sx={{
              color: row.isCustomTranslationEngine ? 'inherit' : 'error',
            }}
          />
        </IconButton>
      </TableCell>
    </TableRow>
  );

  return (
    <>
      {renderPrimary}
      <TranslateEngineDeleteForm
        open={quickDelete.value}
        onClose={quickDelete.onFalse}
        handleDelete={handleDelete}
        deleteitem={row._id}
      />
      <TranslationEngineQuickEdit
        translationEngine={row}
        setTranslateEngine={setTranslateEngine}
        open={quickEdit.value}
        priorityLength={priorityLength}
        onClose={quickEdit.onFalse}
        glossaryList={glossaryList}
        setTranslationEngineMappedLanguageData={setTranslationEngineMappedLanguageData}
      />
    </>
  );
}
